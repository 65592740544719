import React from "react";
import boximg from "../../assets/boximg.png";

function HowItWork() {
  return (
    <div className="howitwork">
      <h3>How It Works</h3>
      <p>Simplifying Predictions: From Exploring Events to Claiming Rewards</p>
      <div className="howitwork_inner">
        <div className="howitwork_inner_left">
          <div className="howitwork_card metricsCard">
            <div className="howitwork_card_count">01</div>
            <h5>Explore Events</h5>
            <p>
              Browse a variety of live prediction events tailored to different
              markets and interests.
            </p>
          </div>
          <div className="howitwork_card metricsCard">
            <div className="howitwork_card_count">02</div>
            <h5>Place Your Bet</h5>
            <p>
              Use your XFI tokens to predict outcomes and compete with others.
            </p>
          </div>
          <div className="howitwork_card metricsCard">
            <div className="howitwork_card_count">03</div>
            <h5>Create Your Own Events</h5>
            <p>Bring your market insights to life and earn platform fees.</p>
          </div>
          <div className="howitwork_card metricsCard">
            <div className="howitwork_card_count">04</div>
            <h5>Claim Your Winnings</h5>
            <p>Easily claim your rewards once outcomes are determined.</p>
          </div>
        </div>
        <div className="howitwork_inner_right">
          <img src={boximg} alt="" />
        </div>{" "}
      </div>
    </div>
  );
}

export default HowItWork;
