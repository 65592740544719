/**
 * About page component.
 *
 * This component renders the about page of the application, including the banner, introduction, how it works, metrics, and FAQ sections.
 *
 * @returns {JSX.Element} The about page component.
 */
import React from "react";
import { Col, Collapse, Row, Skeleton } from "antd";
import "./About.scss";
import { customExpandIcon, faqData, howItWorksData } from "./about.helper";
import useAboutUs from "../../Hooks/useAboutUs";
import { msgs } from "../../utils/appConstants";
import Introduction from "./Introduction";
import { ButtonCustom } from "../../Common/ButtonCustom/ButtonCustom";
import HowItWork from "./HowItWork";
import AboutBanner from "./AboutBanner";
import { isLoggedIn } from "../../utils/helpers/walletHelpers";
import Path from "../../Routing/Constant/RoutePaths";
import { useNavigate } from "react-router-dom";

const About = () => {
  const { metrics, isDisabled, addNetwork } = useAboutUs();
  const navigate = useNavigate();

  return (
    <div className="about">
      <div>
        <AboutBanner />
      </div>
      <div className="about_contanier container">
        <Introduction />
        <div className="about_howWork">
          <h3>{msgs.about.keyFeatures}</h3>
          <p>
            Empowering Your Predictions: Transparency, Innovation, and Rewards
            Redefined
          </p>
          <div className="about_howWork_cards">
            <Row gutter={[15, 15]}>
              {howItWorksData?.map((item, index) => (
                <Col key={index} sm={24} md={12} lg={8} xl={6}>
                  <div className="aboutCommonCard">
                    {item?.icon || "-"}
                    <h5>{item?.title || "-"}</h5>
                    <p>{item?.description || "-"}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
        <HowItWork />
        {/**
         * The about page metrics section.
         */}
        <div className="about_metrics">
          <h3>{msgs.about.metrics}</h3>
          <p>
            Measuring Success: Explore Forecastifi's Growth and Impact at a
            Glance
          </p>
          <div className="about_metrics_cards">
            <Row gutter={[15, 15]}>
              {metrics?.loading
                ? Array.from({ length: 3 }, (_, index) => index + 1).map(
                    (_, index) => (
                      <Col key={index} xs={24} md={12} lg={8}>
                        <div className="recentSkeleton" key={index}>
                          <Skeleton.Input
                            active
                            paragraph={{ rows: 2 }}
                            className=" aboutCardSkelton"
                          />
                        </div>
                      </Col>
                    )
                  )
                : metrics?.data?.map((item, index) => {
                    return (
                      <Col key={index} xs={24} md={12} lg={8}>
                        <div className="metricsCard">
                          <p>{item?.label || "-"}</p>
                          <h5>{item?.value || 0}</h5>
                        </div>
                      </Col>
                    );
                  })}
            </Row>
          </div>
        </div>
        {/**
         * The about page FAQ section.
         */}
        <div className="about_faq">
          <h3>{msgs.about.faq}</h3>
          <div className="about_faq_collapse">
            <Collapse
              items={faqData}
              accordion
              defaultActiveKey={["1"]}
              expandIcon={customExpandIcon}
            />
          </div>
        </div>
        <div className="about_banner">
          <div className="about_banner_inner">
            <h1>{msgs.about.forecasting}</h1>
            <p>
              Forecastifi is redefining the future of prediction markets. Dive
              into a world of endless possibilities and take your predictions to
              the next level. Whether you're here to bet, create, or learn,
              there's a place for you on Forecastifi
            </p>
            {isLoggedIn() ? (
              <ButtonCustom
                btndark
                label="Explore More"
                onClick={() => navigate(Path.MARKETPLACE)}
              />
            ) : (
              <ButtonCustom
                btndark
                label="Sign Up Now"
                onClick={() => addNetwork()}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
