import React from "react";
import continuousOne from "../../assets/continuous-one.svg";
import OurVision from '../../assets/OurVision.svg'

function Introduction() {
  return (
    <div className="introSec">
      <div className="introSec_top">
        <h4 className="aboutCommonHead">Introduction</h4>
        <p>
          Forecastifi is a next-generation crypto betting marketplace that is
          revolutionizing the world of prediction markets by leveraging the
          power of blockchain technology. Built on the robust and highly secure
          CrossFi blockchain, Forecastifi redefines the way users interact with
          prediction markets by providing a transparent, decentralized, and
          innovative platform. It empowers users to place bets on future
          outcomes, create their own markets, and earn rewards—all within a
          secure and intuitive environment.
        </p>
        <br />
        <p>
          Our platform is designed to cater to a wide audience, from experienced
          crypto enthusiasts who appreciate blockchain's transparency to
          newcomers eager to explore the world of decentralized prediction
          markets. Forecastifi offers a unique opportunity to turn insights into
          tangible outcomes by predicting market trends, participating in
          competitive events, or creating custom prediction markets for others
          to engage with.
        </p>
      </div>
      <div className="introSec_bottom">
        <div className="introSec_bottom_card">
          <div className="introSec_bottom_card_img">
            <img src={continuousOne} alt="" />
          </div>
          <h5>Our Mission</h5>
          <p>
            To democratize access to prediction markets by combining the power
            of blockchain with user-centric design. We aim to create a
            transparent and decentralized ecosystem where everyone can safely
            and confidently engage in predictions, win rewards, and build a
            community.
          </p>
        </div>
        <div className="introSec_bottom_card">
          <div className="introSec_bottom_card_img">
            <img src={OurVision} alt="" />
          </div>
          <h5>Our Vision</h5>
          <p>
            To become the leading blockchain-based prediction marketplace,
            fostering trust, innovation, and community-driven engagement. We
            envision a world where prediction markets are not only tools for
            earning but also platforms for learning and collaboration.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Introduction;
