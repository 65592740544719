/**
 * InfoCard component displays key metrics about the betting details of the loggedin user.
 * It fetches data from APIs to display the net position, volume traded, and events traded.
 *
 */
import React from "react";
import { Flex } from "antd";
import { cardData } from "./infoCard.helper";
import useInfoCard from "../../../Hooks/useInfoCard";

const InfoCard = () => {
  const { cardInfo } = useInfoCard();

  return (
    <div className="cardOuter">
      {cardData?.map((item, index) => (
        <div className="cardOne" key={index}>
          <Flex align="center" gap={8}>
            <div className="tradedImg">{item?.icon}</div>
            <h2>{item?.cardName}</h2>
          </Flex>
          <h3>{`${cardInfo[item?.key]}`}</h3>
        </div>
      ))}
    </div>
  );
};

export default InfoCard;
