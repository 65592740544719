/**
 * Activity Profile Component
 *
 * This component displays a table of activities of the logged in user's betting details with pagination.
 * It uses the `useGetActivity` hook to fetch activity data and the `useTablePagination` hook to handle table pagination.
 *
 * @returns {JSX.Element} - Activity Profile component
 */
import { Skeleton, Table } from "antd";
import React from "react";
import { useGetActivity } from "../../../Hooks/useGetActivity";
import PaginationSelect from "../../../Common/PaginationSelect/PaginationSelect";

// function ActivityProfileMemo(key) {
function ActivityProfile() {
  /**
   * Destructured values from useGetActivity hook
   *
   * @type {object}
   * @property {object} metaData - Meta data for the activity profiles
   * @property {array} activityProfileColumns - Columns for the activity profile table
   * @property {function} onRowActivityProfile - Function to handle row clicks
   */
  const {
    metaData,
    activityProfileColumns,
    handleTableChange,
    tableParams,
    onRowActivityProfile,
  } = useGetActivity(true);

  return (
    <div className="positionSec">
      {metaData?.loading ? (
        <div className="no-events-found" data-testid="skeleton-container">
          <Skeleton active paragraph={{ rows: 5 }} />
        </div>
      ) : (
        <Table
          dataSource={metaData?.data}
          pagination={{
            selectComponentClass: PaginationSelect,
            hideOnSinglePage: metaData?.total <= 10 ? true : false,
            ...tableParams?.pagination,
          }}
          onChange={handleTableChange}
          columns={activityProfileColumns}
          className="commonTable"
          onRow={onRowActivityProfile}
        />
      )}
    </div>
  );
}

export default ActivityProfile;
