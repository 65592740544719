import React from "react";
import { Modal } from "antd";
import { LogOutNew } from "../../../assets/StoreAsset/StoreAsset";
import { ButtonCustom } from "../../ButtonCustom/ButtonCustom";

const LogOutModal = (props) => {
  const { open, onOk, onCancel, onclickCancel, isLoading } = props;

  return (
    <>
      <Modal
        className="commonModal"
        centered
        footer={false}
        closable={false}
        open={open}
        onOk={onOk}
        onCancel={onCancel}
      >
        <div className="sureModel">
          <LogOutNew />
          {/* Modal Content */}
          <div className="innerSec">
            <h2>Logout Account?</h2>
            <p>Are you sure you want to logout? </p>
          </div>
          {/* Done Button */}
          <div className="btn">
            <ButtonCustom
              label="Cancel"
              onClick={onclickCancel}
              btnBorder
              disabled={isLoading}
            />
            <ButtonCustom label="Logout" onClick={onOk} disabled={isLoading} />
          </div>
        </div>
      </Modal>
    </>
  );
};
export default LogOutModal;
