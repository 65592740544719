import React from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "./Home.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import banner1 from "../../assets/banner1.svg";
import banner2 from "../../assets/banner2.svg";
import Path from "../../Routing/Constant/RoutePaths";

const Banner = () => {
  const navigate = useNavigate();
  return (
    <div className="banner_outer">
      <Carousel
        autoPlay
        infiniteLoop
        dynamicHeight
        labels={false}
        showArrows={false}
        onClickItem={() => navigate(Path?.ABOUT)}
      >
        <span>
          <img src={banner1} alt="BullBanner" />
        </span>
        <span>
          <img src={banner2} alt="banner2" />
        </span>
      </Carousel>
    </div>
  );
};

export default Banner;
