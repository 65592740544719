import {
  AutoTransmision,
  BatchPrediction,
  Diversity,
  GroupUser,
  MinusBlack,
  PlusWhite,
} from "../../assets/StoreAsset/StoreAsset";

const floorValue = (value) => Math.floor(value || 0);

export const initialMetricsData = (user, volume, events, amount) => [
  { label: "Total Users", value: floorValue(user) || 0, key: "users" },
  { label: "Volume", value: volume || 0, key: "volume" },
  { label: "Events Created", value: floorValue(events) || 0, key: "events" },
];

export const text = `
There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet
`;

export const customExpandIcon = ({ isActive }) => (
  <span>{isActive ? <MinusBlack /> : <PlusWhite />}</span>
);

export const faqData = [
  {
    label: "1. What is Forecastifi?",
    key: "1",
    children: `Forecastifi is a blockchain-powered crypto betting platform that allows users to predict event outcomes, create markets, and earn rewards.`,
  },
  {
    label: "2. How do I participate in events?",
    key: "2",
    children:
      "Simply connect your MetaMask wallet, browse available events, select your prediction, and place your bet using XFI tokens.",
  },
  {
    label: "3. How are event outcomes determined?",
    key: "3",
    children:
      "All outcomes are based on publicly available and verified data from trusted sources like CoinMarketCap.",
  },
  {
    label: "4. Can I create my own events?",
    key: "4",
    children:
      "Yes, Forecastifi allows users to create custom events and earn a percentage of platform fees from the bets placed on them.",
  },
  {
    label: "5. Is my wallet and data secure?",
    key: "5",
    children:
      "Absolutely! We use advanced encryption and blockchain technology to ensure your funds and data remain secure.",
  },
  {
    label: "6. How do I claim my winnings?",
    key: "6",
    children:
      "Once an event's outcome is declared, you can claim your winnings through the 'Bet History' or 'My Bets' section in your dashboard.",
  },
  {
    label: "7. What fees are involved?",
    key: "7",
    children:
      "Forecastifi charges a small platform fee on each bet, and event creators receive a percentage of these fees.",
  },
  {
    label: "8. Can I withdraw funds after placing a bet?",
    key: "8",
    children: (
      <div>
        Yes, users can withdraw their bet{" "}
        <strong>
          as long as no one has placed a bet on the opposite outcome
        </strong>
        . Once a single transaction occurs in both pools, withdrawal will no
        longer be allowed.
      </div>
    ),
  },
  {
    label: "9. What is the minimum and maximum time period for creating events?",
    key: "9",
    children: (
      <div>
        When creating an event, the <strong>minimum time period </strong> is 1
        hour, and the <strong>maximum time period</strong> is 1 year.
      </div>
    ),
  },
  {
    label: "10. When does betting close for an event?",
    key: "10",
    children: (
      <div>
        Betting will automatically close{" "}
        <strong>5 minutes before the event's target date and time</strong>.
        After this cutoff, no further bets can be placed to ensure sufficient
        time for settling and evaluating the event outcome.
      </div>
    ),
  },
  {
    label: "11. Can I cancel an event after creating it?",
    key: "11",
    children: (
      <div>
        No, once an event is published, <strong>it cannot be canceled</strong>{" "}
        to maintain the platform's integrity and reliability.
      </div>
    ),
  },
  {
    label: "12. Can I edit an event after creating it?",
    key: "12",
    children: (
      <div>
        Yes, event creators can edit certain attributes of the event{" "}
        <strong>within 5 minutes of publishing</strong>, provided that no bets
        have been placed on the event.
      </div>
    ),
  },
  {
    label: "13. What are the minimum and maximum bet amounts?",
    key: "13",
    children: (
      <div>
        The minimum bet amount is <strong>10 XFI tokens</strong>, and the{" "}
        <strong>maximum bet amount</strong> is{" "}
        <strong>50,000 XFI tokens</strong>.
      </div>
    ),
  },
  {
    label: "14. How and when are results declared?",
    key: "14",
    children: (
      <div>
        Results are declared{" "}
        <strong>5 minutes after the event's target date and time</strong>. The
        outcomes are determined based on data from trusted sources like
        CoinMarketCap.
      </div>
    ),
  },
  {
    label: "15. How does payout and settlement work?",
    key: "15",
    children: (
      <div>
        Settlement and payout processing begin{" "}
        <strong>5 minutes after the result is declared</strong>. Users must
        manually claim their winnings through the 'Bet History' or 'My Bets'
        section. Winnings will not be credited automatically.
      </div>
    ),
  },
];

export const howItWorksData = [
  {
    icon: <Diversity />,
    title: "Decentralized Transparency",
    description:
      "Every transaction, bet, and payout is securely stored on the blockchain, ensuring immutability and trust. Users have complete visibility into platform activities.",
  },
  {
    icon: <GroupUser />,
    title: "Earn While You Create",
    description:
      "Users can create custom events and earn a percentage of platform fees from bets placed on their events. It’s not just about participating—it's about shaping the market.",
  },
  {
    icon: <BatchPrediction />,
    title: "Real-Time Adjustments",
    description:
      "With a state-of-the-art odds engine, Forecastifi ensures fair and dynamic payout structures based on betting pools, creating engaging opportunities for all participants.",
  },

  {
    icon: <AutoTransmision />,
    title: "Trusted Data Sources",
    description:
      "We rely on publicly available, verified data from CoinMarketCap to determine event outcomes, guaranteeing credibility and fairness.",
  },
];
