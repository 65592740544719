/**
 * Header component that renders the top navigation bar.
 *
 * @returns {JSX.Element} The Header component.
 *
 *
 */
import { Grid } from "antd";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Header.scss";
import HeaderLeft from "./HeaderLeft";
import HeaderRight from "./HeaderRight";
import useCurrentWidth from "../../Hooks/useCurrentWidth";
import { useHandleScroll } from "../../Hooks/useHandleScroll";
import useGetProfile from "../../Hooks/useGetProfile";
import Path from "../../Routing/Constant/RoutePaths";
import Logo from "../../assets/Logo.svg";
import smallLogo from "../../assets/small_logo.svg";
import { decidePathName } from "../../utils/helpers/commonHelper";

const Header = () => {
  const width = useCurrentWidth();
  const isScrolled = useHandleScroll(width);
  const { getProfileDetails } = useGetProfile();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { useBreakpoint } = Grid;
  const { xl } = useBreakpoint();

  useEffect(() => {
    getProfileDetails();
  }, []);

  return (
    <>
      <div
        className={`headerHome ${isScrolled ? "scroll" : ""}`}
        id="home"
        data-testid="header"
      >
        <div className="headerHome_container">
          <div className="logo_location">
            {!xl ? (
              <img
                src={width >= 575 ? Logo : smallLogo}
                alt="Logo"
                onClick={() => navigate(Path?.HOME)}
              />
            ) : null}
            <h3 style={{ fontSize: "14px", color: "#fff" }}>
              {decidePathName(pathname)}
            </h3>
          </div>
          <HeaderLeft />
          <HeaderRight />
        </div>
      </div>
    </>
  );
};

export default Header;
