import React from "react";
import { msgs, socialMediaList } from "../../../utils/appConstants";

const FooterBar = () => {
  return (
    <footer className="footerBar">
      <p>{msgs.copyright}</p>
      <div className="footerBar_socialLinks">
        {socialMediaList.map((item, index) => {
          return (
            <span
              key={index}
              onClick={() => {
                window.open(item?.link);
              }}
              className="links"
            >
              {item?.img}
            </span>
          );
        })}
      </div>
    </footer>
  );
};

export default FooterBar;
