import React from 'react'
import "./About.scss"
import BannerLogo from "../../assets/BannerLogo.png"
import ForeCastifi from "../../assets/ForeCastifi.png"

function AboutBanner() {
  return (
    <div className='aboutBanner'>
      <div className='aboutBanner_top'>
        <h2>Welcome to</h2>
        <img src={ForeCastifi} alt="" />
        <p>Accessible Prediction Markets for all, Empowered by Web 3.0</p>
      </div>
      <div className='aboutBanner_bottom'>
        <img src={BannerLogo} alt="" />
      </div>
    </div>
  )
}

export default AboutBanner